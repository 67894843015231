import React, { useState } from 'react'
import { Link } from 'gatsby'

const BlogTemplate = (blog) => {
  const { title, author, publishDate, image, body, references } = blog

  const renderPublishDate = () => {
    return new Date(`${publishDate} `).toLocaleDateString('en-us', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
  }

  const renderReferences = () => {
    return references.map((str, i) => {
      let output = []
      let textNodes = str.split(' ')

      for (let textNode of textNodes) {
        if (/\.(com|org|edu|gov)\//.test(textNode)) {
          let formattedUrl = textNode
          let lastChar = ''
          if (textNode.endsWith('.') || textNode.endsWith(',')) {
            formattedUrl = textNode.slice(0, -1)
            lastChar = textNode.slice(-1)
          }

          let urlText = formattedUrl

          // force urls not starting with http:// or https:// to be external links
          if (!formattedUrl.startsWith('http')) {
            formattedUrl = '//' + formattedUrl
          }

          output.push(`<a href="${formattedUrl}" target="_blank" rel="noopener noreferrer">${urlText}</a>${lastChar}`)
        } else {
          output.push(textNode)
        }
      }

      output = output.join(' ')

      return <li key={`reference_${i}`} dangerouslySetInnerHTML={{ __html: output }} />
    })
  }

  const backToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <main className="blogArticlePage">
      <div className="article-section">
        <div className="article-main">
          <Link to="/blog" className="back-to-all">
            Back to all articles
          </Link>
          <div className="article-header">
            <h3>{title}</h3>
            <div>By {author}</div>
            <div>Published {renderPublishDate()}</div>
          </div>

          <div className="article-image">
            <img src={image} alt="" />
          </div>

          <div className="article-body" dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
      <div className="article-section-blue">
        <div className="article-references">
          <h2>References</h2>
          <ol>{renderReferences()}</ol>
        </div>
      </div>
      <div className="article-section">
        <div className="back-to-top">
          <a onClick={backToTop}>Back to top</a>
        </div>
      </div>
    </main>
  )
}

export default BlogTemplate
