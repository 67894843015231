import image from '../../images/blog/silent-epidemic.png'

export default {
  url: '/blog/silent-epidemic',
  title: `The Silent Epidemic: Understanding Medication Nonadherence and its Impact`,
  author: `AdhereTech`,
  publishDate: `2024-02-27`,
  image,
  body: `
    <p>
      In the complex landscape of healthcare, the success of medication treatments often hinges on patient adherence to prescribed regimens. A significant and often overlooked issue in healthcare is medication nonadherence, a phenomenon that can have severe consequences for both individual patients and the broader healthcare system (Jimmy & Jose, 2011) up to 125,000 deaths are linked to poor adherence each year (Bosworth et al., 2011).
    </p>
    <p>
      Patients are considered adherent to medications when they take prescribed medication at times recommended by a health care provider and agreed to by the patient (Osterberg & Blaschke, 2005), although according to a report from the World Health Organization (WHO), adherence to long-term therapy is a problem of global magnitude and only averages about 50% in developed countries (www.paho.org, 2003). Nonadherence is a multifaceted issue influenced by various factors such as socioeconomic status, health literacy, mental health, and the complexity of treatment regimens (Lee et al., 2018). 
    </p>
    <p>
      The consequences of medication nonadherence can compromise the effectiveness of treatments, leading to poorer health outcomes, increased hospitalizations, and higher healthcare costs; Medication non-adherence is the single leading cause of preventable health care costs in the US, resulting in $100 to $289 billion in medication costs each year (Lee et al., 2018). Nonadherence is a major obstacle in chronic disease management, where consistent adherence to medications and lifestyle changes is crucial for disease control and prevention of complications. 
    </p>
    <p>
      As nonadherence is such a prevalent issue, many solutions have been identified for addressing it, but it still remains a largely unsolved issue which is worse in patients from lower socioeconomic classes (Oates et al., 2020). Improving health literacy through patient education can empower individuals to better understand their conditions, treatment plans, and the importance of adherence (effectivehealthcare.ahrq.gov, n.d.). Studies have shown that patients with higher levels of health literacy are, on average, 14% more adherent to medication than patients with low health literacy skills (Taibanguay et al., 2019).
    </p>
    <p>
      Previous research has shown that as dosing frequency increases, adherence to medication decreases. Healthcare providers can work towards simplifying treatment regimens, considering factors such as dosing frequency, pill burden, and potential side effects to enhance patient adherence (CDC, 2023).
    </p>
    <p>
      Another solution identified for increasing adherence is tailoring interventions based on individual patient needs and addressing specific barriers to adherence (Ingersoll and Cohen, 2008). Personalized medicine has been found to be directly linked to medication adherence (Chapman et al., 2020). Closely linked to tailored interventions, is collaboration among stakeholders. Physicians, pharmacists, pharmaceutical companies, payers and nurses can create a comprehensive support system to monitor and address nonadherence (Egan and Philipson, 2015). 
    </p>
    <p>
      Medication nonadherence poses a significant challenge to healthcare providers and patients alike, leading to increased suffering, death and avoidable healthcare costs (Goldstein, Gathright and Garcia, 2017). By implementing effective monitoring strategies, the healthcare community can work towards mitigating the impact of nonadherence and improving overall health outcomes for individuals and communities. As medication adherence is often down-played (possibly due to its inherent difficulty in solving), the first step in solving the issue of medication nonadherence begins with acknowledgement of the problem. This is a collective effort that requires collaboration, understanding, and a commitment to patient-centered care.
    </p>
  `,
  references: [
    `Jimmy, Benna, & Jose, J. (2011). Patient medication adherence: Measures in daily practice. <em>Oman medication Journal</em>, 26(3), 155–159. https://doi.org/10.5001/omj.2011.38`,
    `Bosworth HB;Granger BB;Mendys P;Brindis R;Burkholder R;Czajkowski SM;Daniel JG;Ekman I;Ho M;Johnson M;Kimmel SE;Liu LZ;Musaus J;Shrank WH;Whalley Buono E;Weiss K;Granger CB; (no date) <em>Medication adherence: A call for action, American heart journal</em>. Available at: https://pubmed.ncbi.nlm.nih.gov/21884856/ (Accessed: 20 February 2024). `,
    `Osterberg, L., & Blaschke, T. (2005). Adherence to medication. <em>New England Journal of Medicine</em>, 353(5), 487–497. https://doi.org/10.1056/nejmra050100 `,
    `2003, 11 Sep, <em>Who. adherence to long-term therapies: Evidence for action, 2003, PAHO/WHO | Pan American Health Organization</em>. Available at: https://www.paho.org/en/documents/who-adherence-long-term-therapies-evidence-action-2003 (Accessed: 20 February 2024). `,
    `Lee, S.-Q., Raamkumar, A. S., Li, J., Cao, Y., Witedwittayanusat, K., Chen, L., & Theng, Y.-L. (2018). Reasons for primary medication nonadherence: A systematic review and metric analysis. <em>Journal of Managed Care & Specialty Pharmacy, 24</em>(8), 778–794. https://doi.org/10.18553/jmcp.2018.24.8.778 `,
    `Oates, G. R., Juarez, L. D., Hansen, B., Kiefe, C. I., & Shikany, J. M. (2020). Social risk factors for medication nonadherence: Findings from the Cardia Study. <em>American Journal of Health Behavior, 44</em>(2), 232–243. https://doi.org/10.5993/ajhb.44.2.10 `,
    `Agency for Healthcare Research and Quality (AHRQ) website. Closing the quality gap series: medication adherence interventions: comparative effectiveness https://effectivehealthcare.ahrq.gov/products/medication-adherence-interventions/research`,
    `Taibanguay, N., Chaiamnuay, S., Asavatanabodee, P., & Narongroeknawin, P. (2019a). Effect of patient education on medication adherence of patients with rheumatoid arthritis: A randomized controlled trial. <em>Patient Preference and Adherence, Volume 13</em>, 119–129. https://doi.org/10.2147/ppa.s192008 `,
    `Miller, T. A. (2016). Health literacy and adherence to medication treatment in chronic and acute illness: A meta-analysis. <em>Patient Education and Counseling</em>, 99(7), 1079–1086. https://doi.org/10.1016/j.pec.2016.01.020 `,
    `Centers for Disease Control and Prevention. Chronic disease prevention and health promotion. Available at: http://www.cdc.gov/nccdphp/. Accessed July 27, 2012.`,
    `Ingersoll, K. S., & Cohen, J. (2008). The impact of medication regimen factors on adherence to chronic treatment: A review of literature. <em>Journal of Behavioral Medicine, 31</em>(3), 213–224. https://doi.org/10.1007/s10865-007-9147-y `,
    `Chapman, S., Sibelli, A., St-Clair Jones, A., Forbes, A., Chater, A., & Horne, R. (2020). Personalised adherence support for maintenance treatment of inflammatory bowel disease: A tailored digital intervention to change adherence-related beliefs and barriers. <em>Journal of Crohn’s and Colitis, 14</em>(10), 1394–1404. https://doi.org/10.1093/ecco-jcc/jjz034 `,
    `Egan, M., & Philipson, T. (2015). Health Care Adherence and Personalized Medicine. <em>SSRN Electronic Journal</em>. https://doi.org/10.2139/ssrn.2740031`,
    `Goldstein, C. M., Gathright, E. C., & Garcia, S. (2017). Relationship between depression and medication adherence in cardiovascular disease: The perfect challenge for the integrated care team. <em>Patient Preference and Adherence, Volume 11</em>, 547–559. https://doi.org/10.2147/ppa.s127277`,
  ],
}
